import React from 'react';
import { Container, Row, Col, Card, Form, Image } from 'react-bootstrap';
import axios from 'axios';

import logo from '../assets/images/logo.png';
import { useAuth } from '../context/auth';
import { InputField } from '../components/common';
import useForm from '../components/form/useForm';
import validate from '../components/form/LoginValidation';
import useCommit from '../utils/useCommit';

const SignIn = () => {
  const { setAuth } = useAuth();
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  const userSignIn = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { email, password } = values;
      const data = {
        email,
        password
      };
      axios.post('/api/customers/signin', data).then(res => {
        if (res.data.status === 204) {
          setMessage({
            type: 'danger',
            text: res.data.message
          });
          setIsCommitting(false);
        }
        if (res.data.status === 0) {
          const user = {
            user_id: res.data.data.id
          }
          axios.post('/api/customers/token-create', user).then(res => {
            if (res.data.status === 'success') {
              checkAuthToken(res.data, password);
            }
          })
          .catch(error => {
            console.log(error);
          });
        }
        if (res.data.status === 1) {
          checkAuthToken(res.data, password);
        }
      })
      .catch(error => {
        console.log(error);  
      });
    }
  }

  const checkAuthToken = (data, password) => {
    if (Object.keys(data).length > 0) {
      const secrets = {
        client_id: data.data.id,
        client_secret: data.data.secret,
        grant_type: 'password',
        username: data.data.user.email,
        password: password,
        scope: '*'
      }
      axios.post('/oauth/token', secrets).then(res => {
        setAuth({ 
          access_token: res.data.access_token,
          id: data.data.user.id,
          name: data.data.user.name,
          permission: data.permission
        });
        setIsCommitting(false);
        window.location.href = '/dashboard';
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSubmit 
  } = useForm(userSignIn, validate);
  
  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="Dashboard-ContentBody__Form Dashboard-ContentBody__Form--SignIn">
        <Container>
          <Row>
            <Col>
              <div className="text-center mt-4 mb-4">
                <a href="https://geekscrs.com.au" className="Dashboard-Header__Brand">
                  <Image fluid src={logo} alt="Geeks CRS" />
                </a>
              </div>
              <Card className="border-0">
                <Card.Body>
                  <Form onSubmit={handleSubmit} noValidate>
                    <h3 className="text-dark mb-3">Please, Sign in</h3>
                    <AlertMessage />
                    <InputField
                      type="text"
                      name="email"
                      label="Email address"
                      value={values.email || ''}
                      onChange={handleChange}
                      errorMsg={errors.email}
                      required
                    />
                    <InputField
                      type="password"
                      name="password"
                      label="Password"
                      value={values.password || ''}
                      onChange={handleChange}
                      errorMsg={errors.password}
                      required
                    />
                    <FormSubmit
                      block
                      type="submit"
                      variant="primary"
                      btnText="Sign In"
                    />
                    <a 
                      href="https://geekscrs.com.au/password/reset" 
                      className="mt-2 d-inline-block"
                    >Forgot password?</a>
                  </Form>
                </Card.Body>
              </Card>
              <p className="text-center mt-3">&copy; {new Date().getFullYear()} Geeks CRS.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default SignIn;