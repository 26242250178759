import React from 'react';
import { Container, Row, Col, Dropdown, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import avatar from '../assets/images/avatar.png';
import { useAuth } from '../context/auth';

const Header = () => {
  const { auth, signOut } = useAuth();

  return (
    <header className="Dashboard-Header">
      <Container>
        <Row>
          <Col>
            <Link 
              to="/dashboard"
              className="Dashboard-Header__Brand"
            >
              <Image fluid src={logo} alt="Geeks CRS Partner" />
            </Link>
          </Col>
          <Col>
            <ul className="Dashboard-Header__UserNav">
              <li>
                <Dropdown>
                  <Dropdown.Toggle id="sla-navBar">
                    <span className="Dashboard-Header__UserNav--Avatar">
                      <Image src={avatar} alt="Loggedin user image" />
                    </span>
                    <span className="Dashboard-Header__UserNav--Name">{auth.name}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="shadow border-0" alignRight>
                    <Link to="/profile/edit" className="dropdown-item">Your profile</Link>
                    <Link to="/profile/change-password" className="dropdown-item">Change password</Link>
                    <Dropdown.Divider />
                    <Link to="#" className="dropdown-item" onClick={signOut}>Sign out</Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </header>
  );
}

export default Header;