import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Navbar, Image, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import logo from '../assets/images/logo.png';
import videoPlayBtn from '../assets/images/play-btn.png';
import PopupJoin from '../components/PopupJoin';
import PopupSignin from '../components/PopupSignin';
import PopupVideo from '../components/PopupVideo';
import ForgetPassword from '../components/ForgetPassword';
import axios from 'axios';

const LandingPage = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalSignin, setModalSignin] = useState(false);
  const [modalVideo, setModalVideo] = useState(false);
  const [modalForgetPassword, setModalForgetPassword] = useState(false);

  const [homeSettings, setHomeSettings] = useState({
    service_sec_image: '',
    geeks_sec_image: '',
    benefit_sec_image: '',
    video_thumb: '',
    video_url: '',
  });

  const bookNow = () => {
    const link = document.createElement('a');
    link.href = 'https://geekscrs.com.au/book-online';
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  }
  const parentcallbackAction = () =>{
    setModalSignin(false);
    setModalForgetPassword(true);
  }
  useEffect(() => {
    axios.get('/api/customers/langing-page-settings').then(res => {
      setHomeSettings(res.data);
      setIsLoaded(true);
    }).catch(error => {

    });
  }, [])
  return (
      isLoaded &&
    <div className="LandingPage">
      <Container>
        <Row>
          <Col>
            <Navbar expand="lg">
              <Navbar.Brand href="/">
                <Image fluid src={logo} alt="Geeks CRS Partner" /><br />
                {/* <small className="LandingPageSlogan">Earn smartly with Geeks</small> */}
              </Navbar.Brand>
              <p className="ml-md-auto">
                <span className="d-none d-md-inline-block ">Already a Customer?</span> <Button variant="primary" className="ml-3" onClick={() => setModalSignin(true)}>Sign In</Button>
              </p>
              <PopupSignin 
                show={modalSignin}
                onHide={() => setModalSignin(false)}
                parentcallback={ parentcallbackAction }
              />
              <ForgetPassword 
                show={modalForgetPassword}
                onHide={() => setModalForgetPassword(false)}
              />
            </Navbar>
          </Col>
        </Row>
      </Container>

      <div className="HeroBanner bg-white" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URI+'/'+homeSettings.service_sec_image})` }}>
        <div className="pt-sm-5 mt-sm-5">
          <Container>
            <Row>
              <Col md={5}>
                <div className="LandingPageHeroText">
                  <h3 className="HeroBanner-Title text-uppercase font-weight-bold">Book our <span className="text-primary">Service</span></h3>
                  <p className="LandingPageHeroText-SubHeading">Our technicians are highly experienced in Desktop and Laptop Computer fixing. Book Online to save 10% OFF on Weekdays and 20% OFF on Weekends.</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <div className="pb-5" style={{ backgroundColor: '#f7f7f7' }}>
        <Container>
          <Row>
            <Col>
              <div className="UserAccessArea">
                <div className="pt-5 mt-5">
                  <Row>
                    <Col md={6}>
                      <div className="mx-auto">
                        <Image fluid src={process.env.REACT_APP_API_URI+'/'+homeSettings.geeks_sec_image} />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="pt-0">
                        <h2 className="GeneralFaqs-Title LandingPageTitle">Looking for tech Geek?</h2>
                        <ul className="GeneralFaqs-Listing LandingPageListing">
                          <li>Trade Business</li>
                          <li>Tech ShopFront</li>
                          <li>Online Businesses</li>
                          <li>Must have 250+ customers</li>
                        </ul>
                        <Button
                          size="lg"
                          variant="primary"
                          onClick={bookNow}
                        >Book Now</Button>
                      </div>
                      <PopupJoin 
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="GeneralFaqs bg-white">
        <div className="pt-5">
          <Container>
            <Row>
              <Col md={6}>
                <h2 className="GeneralFaqs-Title LandingPageTitle">Customer's Benefit</h2>
                <ul className="GeneralFaqs-Listing LandingPageListing">
                  <li className="lead">Access to customer Portal</li>
                  <li className="lead">Making computer appointments.</li>
                  <li className="lead">Your customers pay less than our usual fees when they book through you</li>
                  <li className="lead">A dedicated team lead to support you and your customers</li>
                  <li className="lead">Extra revenues for your business through attractive commissions</li>
                  <li className="lead">Building a wider professional networks that helps growing your business</li>
                </ul>
              </Col>
              <Col md={6}>
                <div className="mx-auto">
                  <Image fluid src={process.env.REACT_APP_API_URI+'/'+homeSettings.benefit_sec_image} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="VideoSection">
              <div className="pt-5 pb-5 mt-5 mb-5">
                <Row className="align-items-center">
                  <Col md={6} lg={8}>
                    <div className="w-100 rounded overflow-hidden position-relative">
                      <Image fluid src={process.env.REACT_APP_API_URI+'/'+homeSettings.video_thumb} />
                      <Link 
                        to="#"
                        onClick={() => setModalVideo(true)}
                        className="VideoPlayBtn"
                      ><Image fluid src={videoPlayBtn} /></Link>
                      <PopupVideo 
                        videoUrl = {homeSettings.video_url}
                        show={modalVideo}
                        onHide={() => setModalVideo(false)}
                      />
                    </div>
                  </Col>
                  <Col md={6} lg={4}>
                    <div className="text-md-right pt-4 pt-md-0">
                      <p className="lead mb-0">To find out more about what we do</p>
                      <p className="lead mb-0">Please visit - <a href="https://geekscrs.com.au" target="_blank" className="lead">www.geekscrs.com.au</a></p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
 
export default LandingPage;