import React, { useState } from 'react';
import { Container, Row, Col, Image, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import logo from '../assets/images/logo.png';
import useCommit from '../utils/useCommit';

const ResetPassword = (props) => {
  const [password, setPassword] = useState('');
  const [retypePassword, setRetypePassword] = useState('');
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();
  const [errors, setErrors] = useState({
    password: '',
    retypePassword: ''
  });

  const handleChangePassword = (event) => {
    const value = event.target.value;

    if (value) {
      setPassword(value);
    } else {
      setErrors({
        ...errors,
        password: 'Password is required!'
      });
    }
  }

  const handleChangeRetypePassword = (event) => {
    const value = event.target.value;
    
    if (value) {
      setRetypePassword(value);
    } else {
      setErrors({
        ...errors,
        retypePassword: 'Retype password is required!'
      });
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    if (password && retypePassword) {
      if (password === retypePassword) {
        setIsCommitting(true);
        const data = {
          code: props.match.params.code,
          password: password
        }
        axios.post('/api/partners/password/reset', data).then(res => {
          setIsCommitting(false);
          if (res.data.status === 'success') {
            setMessage({
              type: 'success',
              text: res.data.message
            });
          } else {
            setMessage({
              type: 'danger',
              text: res.data.message
            });
          }
        }).catch(error =>{

        });
      } else {
        setErrors({
          ...errors,
          retypePassword: 'Retype password is not matched!'
        });
      }
    } else {
      if (!password) {
        setErrors({
          ...errors,
          password: 'Password is required!'
        });
      } else if (!retypePassword) {
        setErrors({
          ...errors,
          retypePassword: 'Retype password is required!'
        });
      }
    }
  }

  return (
    <div className="h-100 d-flex justify-content-center align-items-center">
      <div className="Dashboard-ContentBody__Form Dashboard-ContentBody__Form--SignIn">
        <Container>
          <Row>
            <Col>
              <div className="text-center mt-4 mb-4">
                <a href="/" className="Dashboard-Header__Brand">
                  <Image fluid src={logo} alt="Geeks CRS" />
                </a>
              </div>
              <Card className="border-0 mx-auto shadow" style={{ maxWidth: '450px' }}>
                <Card.Body>
                  <AlertMessage />
                  <Form onSubmit={handleSubmit} noValidate>
                    <Form.Group controlId="newPassword">
                      <Form.Label>New password</Form.Label>
                      <Form.Control 
                        type="password" 
                        value={password}
                        onChange={handleChangePassword}
                        isInvalid={!!errors.password}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId="retypeNewPassword">
                      <Form.Label>Retype new password</Form.Label>
                      <Form.Control 
                        type="password" 
                        value={retypePassword}
                        onChange={handleChangeRetypePassword}
                        isInvalid={!!errors.retypePassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.retypePassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <FormSubmit
                      block
                      type="submit"
                      variant="primary"
                      btnText = "Reset password"
                    />
                  </Form>
                </Card.Body>
              </Card>
              <p className="text-center mt-3">&copy; {new Date().getFullYear()} Geeks CRS.</p>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
 
export default ResetPassword;