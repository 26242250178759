import React from 'react';
import { Row, Image, Col, Card, Form } from 'react-bootstrap';
import axios from 'axios';

import Layout from '../components/Layout';
import { InputField } from '../components/common';
import useForm from '../components/form/useForm';
import validate from '../components/form/referalValidation';
import useCommit from '../utils/useCommit';
import bannerAds from '../assets/images/refer-rewards.png';

const Refers = () => {
  const { setIsCommitting, FormSubmit, setMessage, AlertMessage } = useCommit();

  const sendReferal = () => {
    if (Object.keys(values).length > 0 && Object.keys(errors).length === 0) {
      setIsCommitting(true);
      const { refName, refEmail } = values;
      const data = {
        ref_name: refName,
        ref_email: refEmail
      };

      axios.post('/api/customers/refer-rewards', data)
        .then(res => {
            console.log(res.data);
          setMessage({
            type: res.data.status === 'error' ? 'danger' : 'success',
            text: res.data.message
          });
          setIsCommitting(false);
          handleFormReset();
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  const { 
    values, 
    errors, 
    handleChange, 
    handleSubmit, 
    handleFormReset
  } = useForm(sendReferal, validate);


  return (
    <Layout dataIsLoaded={true}>
      <div className="Dashboard-ContentBody__Header">
        <h1 className="Dashboard-ContentBody__Header--Title">
          Refer & Rewards
        </h1>
      </div>
      <div className="Dashboard-ContentBody__Form">
        <Row>
          <Col xs={12}>
            <div className="w-100 mb-4">
              <Image fluid src={bannerAds} />
            </div>
          </Col>
          <Col md="12">
            <AlertMessage />
            <Card className="shadow-sm">
              <Card.Body>
                  <Row>
                    <Col className="col-md-8 offset-md-2">
                        <p style={{ 'fontSize': '16px', }}>Requite your loved ones with Geeks CRS's finest services, all at a 20% discount when they book our services online; just like you like ’em! Call us to learn more. 1300 912 176</p>
                        <p style={{ 'fontSize': '16px', }}>Refer our services and get 20% off on your next transaction with us while you do it! Please complete the form below to proceed further, rest assured this information will be confidential.</p>
                        <h4>Referral's Details</h4>
                        <Form onSubmit={handleSubmit} noValidate>
                            <InputField
                                type="text"
                                name="refName"
                                value={values.refName || ''}
                                placeholder="Referral person name"
                                onChange={handleChange}
                                errorMsg={errors.refName}
                                required
                            />
                            <InputField
                                type="email"
                                name="refEmail"
                                value={values.refEmail || ''}
                                placeholder="Referral person email"
                                onChange={handleChange}
                                errorMsg={errors.refEmail}
                                required
                            />
                            <FormSubmit
                                type="submit"
                                variant="primary"
                                btnText="Send Referral"
                            />
                            </Form>
                    </Col>
                  </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </Layout>
  )
}

export default Refers;